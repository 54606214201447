import { Typography } from '@mui/material';
import { useTranslate } from 'react-admin';

interface ISectionTitleProps {
  label: string;
}

function SectionTitle({ label }: ISectionTitleProps) {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  );
}

export default SectionTitle;
