import { zodResolver } from '@hookform/resolvers/zod';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab, Tabs } from '@mui/material';
import {
  type Dispatch,
  type SetStateAction,
  type SyntheticEvent,
  useState,
  useEffect,
} from 'react';
import { useTranslate, useRedirect, type Identifier, useNotify } from 'react-admin';
import { type UseFormReturn, type SubmitHandler, useForm, FormProvider } from 'react-hook-form';

import { companiesDataProvider } from '@/dataProvider';
import { ICompanyItemResponseParams } from '@/types';
import {
  CompanyInfoTab,
  CompanySettingsTab,
} from '@/view/companies/CompaniesEdit/components/form/EditCompanyForm/FormTabs';
import { showErrorNotification } from '@/view/companies/CompaniesEdit/components/form/EditCompanyForm/utils';
import { getCompanyFormValidationSchema } from '@/view/companies/CompaniesEdit/components/form/EditCompanyForm/validation';

export type TActiveTab = 'company_info' | 'company_settings';
export type TSetActiveTab = Dispatch<SetStateAction<TActiveTab>>;
export type TFormMethods = UseFormReturn<ICompanyItemResponseParams>;

export interface IAddCompanyFormProps<FormValuesType> {
  companyId: Identifier;
  formDefaultValues: FormValuesType;
}

function EditCompanyForm({
  companyId,
  formDefaultValues,
}: IAddCompanyFormProps<ICompanyItemResponseParams>): JSX.Element {
  const t = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();

  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<TActiveTab>('company_info');
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [logo, setLogo] = useState<string>('');
  const [contractUrl, setContractUrl] = useState<string>('');
  const [contractFileName, setContractFileName] = useState<string>('');

  const validationSchema = getCompanyFormValidationSchema(t);
  const methods = useForm<ICompanyItemResponseParams>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: formDefaultValues,
    resolver: zodResolver(validationSchema),
  });
  const {
    getValues,
    // formState: { errors },
  } = methods;

  const handleResetForm = (): void => {
    methods.reset();
    setSelectedDates([]);
    setActiveTab('company_info');
    redirect('/companies');
  };

  const isNextBtnDisabled =
    !getValues('legal_name') ||
    !getValues('company_name') ||
    !getValues('admins.0.first_name') ||
    !getValues('admins.0.last_name') ||
    !getValues('admins.0.email');

  const handleChangeTab = (_event: SyntheticEvent, newValue: TActiveTab): void => {
    if (newValue === 'company_settings' && isNextBtnDisabled) {
      console.log('Form is not valid. Complete all required fields correctly.');
      return;
    }
    setActiveTab(newValue);
  };

  const onSubmit: SubmitHandler<ICompanyItemResponseParams> = async (data): Promise<void> => {
    setLoading(true);

    const pay_days = selectedDates.map(date => date.toISOString());

    const result = await companiesDataProvider.editCompany(
      companyId,
      {
        ...data,
        pay_days,
        logo,
        contract_url: contractUrl,
        withdrawal_method: 'Card',
      },
      () => {
        setLoading(false);
      },
      // eslint-disable-next-line
      (error: any) => {
        if (error && error?.response?.data?.message) {
          showErrorNotification(error.response.data.message, notify, setActiveTab);
        }
        setLoading(false);
      },
    );

    if (result) {
      handleResetForm();
    }
  };

  useEffect(() => {
    if (formDefaultValues.pay_days && formDefaultValues.pay_days.length) {
      const dates = formDefaultValues.pay_days.map(d => new Date(d));
      setSelectedDates(dates);
    }
  }, [formDefaultValues.pay_days]);

  useEffect(() => {
    if (formDefaultValues.logo) {
      setLogo(formDefaultValues.logo);
    }
  }, [formDefaultValues.logo]);

  useEffect(() => {
    if (formDefaultValues.contract_url) {
      setContractFileName(`${t('resources.company.form.add_company.contract')}.pdf`);
      setContractUrl(formDefaultValues.contract_url);
    }
  }, [formDefaultValues.contract_url]);

  return (
    <Box sx={{ width: 975 }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <TabContext value={activeTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTab} onChange={handleChangeTab} aria-label="company form tabs">
                <Tab
                  label={t('resources.company.form.add_company.company_info')}
                  value="company_info"
                />
                <Tab
                  label={t('resources.company.form.add_company.company_settings')}
                  value="company_settings"
                />
              </Tabs>
            </Box>
            <TabPanel value="company_info">
              <CompanyInfoTab
                methods={methods}
                isNextBtnDisabled={isNextBtnDisabled}
                setActiveTab={setActiveTab}
                setLogo={setLogo}
                contractUrl={contractUrl}
                setContractUrl={setContractUrl}
                contractFileName={contractFileName}
                setContractFileName={setContractFileName}
              />
            </TabPanel>
            <TabPanel value="company_settings">
              <CompanySettingsTab
                loading={loading}
                methods={methods}
                setActiveTab={setActiveTab}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                handleResetForm={handleResetForm}
              />
            </TabPanel>
          </TabContext>
        </form>
      </FormProvider>
    </Box>
  );
}

export default EditCompanyForm;
