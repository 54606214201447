import { type Identifier } from 'react-admin';

import { API_PATHS } from '@/api';
import { getData, postData, putData } from '@/api/crudHandlers';
import {
  ICompanyRequestParams,
  ICompaniesRequestParams,
  ICompaniesResponseParams,
  ICompanyItemRequestParams,
  ICompanyItemResponseParams,
  TAxiosResponseSuccess,
} from '@/types';

export const companyHandlers = {
  CREATE_COMPANY(data: ICompanyRequestParams): TAxiosResponseSuccess<never> {
    return postData<never>(API_PATHS.COMPANY.CREATE._, {
      data,
    });
  },
  // eslint-disable-next-line
  UPLOAD_LOGO(data: FormData): TAxiosResponseSuccess<any> {
    // eslint-disable-next-line
    return postData<any>(API_PATHS.COMPANY.UPLOAD_LOGO._, {
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  // eslint-disable-next-line
  UPLOAD_CONTRACT(data: FormData): TAxiosResponseSuccess<{
    url: string;
  }> {
    // eslint-disable-next-line
    return postData<{
      url: string;
    }>(API_PATHS.COMPANY.UPLOAD_CONTRACT._, {
      data: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  GET_LIST(data?: ICompaniesRequestParams): TAxiosResponseSuccess<ICompaniesResponseParams> {
    return getData<ICompaniesResponseParams>(API_PATHS.COMPANY.LIST._, {
      params: {
        ...data,
      },
    });
  },
  GET_ITEM_BY_ID(
    data: ICompanyItemRequestParams,
  ): TAxiosResponseSuccess<ICompanyItemResponseParams> {
    return getData<ICompanyItemResponseParams>(API_PATHS.COMPANY.ITEM.BY_ID(data.companyId));
  },
  EDIT_COMPANY_ITEM(
    companyId: Identifier,
    data: ICompanyItemResponseParams,
  ): TAxiosResponseSuccess<ICompanyItemResponseParams> {
    return putData<ICompanyItemResponseParams>(
      API_PATHS.COMPANY.EDIT_COMPANY_ITEM.BY_ID(companyId),
      {
        data,
      },
    );
  },
  GET_COMPANY(data?: { id: string }): TAxiosResponseSuccess<any> {
    return getData(`/company/${data?.id}`);
  },
};

export const adminHandlers = {
  GET_LIST(data?: ICompaniesRequestParams): TAxiosResponseSuccess<ICompaniesResponseParams> {
    return getData<any>(API_PATHS.ADMINS.LIST._, {
      params: {
        ...data,
      },
    });
  },
};
