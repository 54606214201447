import { ICompanyPaths } from '@/types';

export const COMPANY_PATHS: ICompanyPaths = {
  CREATE: {
    _: `/company/create/`,
  },
  UPLOAD_LOGO: {
    _: `/company/upload-logo/`,
  },
  UPLOAD_CONTRACT: {
    _: '/company/upload-contract/',
  },
  LIST: {
    _: `/company/list/`,
  },
  ITEM: {
    BY_ID: companyId => `/company/${companyId}/`,
  },
  EDIT_COMPANY_ITEM: {
    BY_ID: companyId => `/company/${companyId}/edit/`,
  },
  GET_COMPANY: {
    _: `/company/`,
  },
} as const;

export const ADMIN_PATHS = {
  LIST: {
    _: `/admins/list/`,
  },
};
