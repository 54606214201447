import { authHandlers } from '@/api/apiHandlers/authHandlers';
import { adminHandlers, companyHandlers } from '@/api/apiHandlers/companyHandlers';
import { dashboardHandlers } from '@/api/apiHandlers/dashboardHandlers';
import { employeesHandlers } from '@/api/apiHandlers/employeesHandlers';
import { filtersHandlers } from '@/api/apiHandlers/filtersHandlers';
import { transactionsHandlers } from '@/api/apiHandlers/transactionsHandlers';

export const API_HANDLERS = {
  AUTH: authHandlers,
  COMPANY: companyHandlers,
  EMPLOYEES: employeesHandlers,
  FILTERS: filtersHandlers,
  ADMINS: adminHandlers,
  TRANSACTIONS: transactionsHandlers,
  DASHBOARD: dashboardHandlers,
} as const;
