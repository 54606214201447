import { createCompany } from '@/dataProvider/companiesDataProvider/createCompany';
import { editCompany } from '@/dataProvider/companiesDataProvider/editCompany';
import { getItem } from '@/dataProvider/companiesDataProvider/getItem';
import { getCompany, getList } from '@/dataProvider/companiesDataProvider/getList';
import { uploadContract } from '@/dataProvider/companiesDataProvider/uploadContract';
import { uploadLogo } from '@/dataProvider/companiesDataProvider/uploadLogo';

export const companiesDataProvider = {
  createCompany,
  getList,
  uploadLogo,
  getItem,
  editCompany,
  getCompany,
  uploadContract,
};
